/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import styled from "styled-components";
import { useOrochi } from "../context/OrochiContext";
import { foodData } from "../data/foodData";
import { SEO } from "../SEO";
import { useTranslation } from "react-i18next";
import Food from "../components/Food";
import Drinks from "../components/Drinks";
import getResponsiveImageUrl from "../utils/getResponsiveImageUrl";
import ResponsiveImage from "../components/Img/ResponsiveImage";

const foodCategoryKeyValuePairs = [
  { name: "Starters", name_fr: "Entrées", link: "starters" },
  {
    name: "Tacos (3 pcs)",
    name_fr: "Tacos (3 pièces)",
    link: "tacos",
  },
  {
    name: "Tataki (6 slices)",
    name_fr: "Tataki (6 tranches)",
    link: "tataki",
  },
  {
    name: "Platters (omakase)",
    name_fr: "Plateaux (omakase)",
    link: "omakase",
  },
  {
    name: "Classic Makis 6 pcs",
    name_fr: "Makis Classiques 6 morceaux",
    link: "makis",
  },
  {
    name: "Ura Makis - 6 pcs",
    name_fr: "Ura Makis - 6 morceaux",
    link: "ura-makis",
  },
  {
    name: "Premium Makis 8pcs",
    name_fr: "Premium Makis 8 morceaux",
    link: "premium-makis",
  },
  {
    name: "Yakitori 2 Skewers",
    name_fr: "Yakitori 2 Brochettes",
    link: "kushiyaki",
  },
  {
    name: "Hot Dishes",
    name_fr: "Plats Chauds",
    link: "hot-dishes",
  },
  {
    name: "Omakase (5 Course)",
    name_fr: "Omakase (5 Course)",
    link: "omakase",
  },
  {
    name: "Extras",
    name_fr: "Accompagnement",
    link: "extras",
  },
  { name: "Desserts", name_fr: "Desserts", link: "desserts" },
];
const drinksCategoryKeyValuePairs = [
  {
    name: "Cocktails",
    name_fr: "Cocktails",
    link: "cocktails",
  },
  {
    name: "Mocktails",
    name_fr: "Mocktails",
    link: "mocktails",
  },
  {
    name: "Whisky",
    name_fr: "Whisky",
    link: "whisky",
  },
  {
    name: "Sakes",
    name_fr: "Sakés",
    link: "sakes",
  },
];
const Menu = () => {
  const { t, i18n } = useTranslation();
  const {
    dispatch,
    state: { menuButtons, foodMenuImageName, menuTab },
  } = useOrochi();
  const [index, setIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isActive, setIsActive] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    name: "",
    name_fr: "",
    link: "",
  });
  const [selectedCategoryDrinks, setSelectedCategoryDrinks] = useState({
    name: "",
    name_fr: "",
    link: "",
  });
  const dropdownSection = useRef();
  const menuContainer = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownSection.current &&
        !dropdownSection.current.contains(event.target) &&
        !menuContainer.current.contains(event.target)
      ) {
        setIsActive(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isActive]);

  useLayoutEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  // const imageFadeFn = useCallback(() => {
  //   index < 5 ? setIndex((prev) => prev + 1) : setIndex(1);
  // }, [index]);
  // useEffect(() => {
  //   const sliderInterval = setInterval(() => {
  //     imageFadeFn();
  //   }, 5000);
  //   return () => {
  //     clearInterval(sliderInterval);
  //   };
  // });
  const menuSelectFn = useCallback(
    (value) => {
      dispatch({ type: "menu-buttons", payload: value });
    },
    [dispatch]
  );
  // const menuTabFn = useCallback(
  //   (value) => {
  //     dispatch({ type: "menu-tab", payload: value });
  //     value !== "menu" ? menuSelectFn("wine") : menuSelectFn("brunch");
  //   },
  //   [dispatch, menuSelectFn]
  // );
  const handleSelectedCategory = (value) => {
    setSelectedCategory(foodCategoryKeyValuePairs[value]);
    setIsActive(false);
  };
  const handleSelectedCategoryDrinks = (value) => {
    setSelectedCategoryDrinks(drinksCategoryKeyValuePairs[value]);
    setIsActive(false);
  };
  // console.log(selectedCategory)
  return (
    <MainDiv
      // selectedFood={
      //   windowWidth > 991
      //     ? food[food?.findIndex((el) => el.id === foodMenuImageName)]
      //         ?.image
      //     : food[index]?.image
      // }
      bgImage={getResponsiveImageUrl(
        windowWidth >= 991
          ? "menu/drunken-dragon-menu_BG.webp"
          : "menu/drunken-dragon-menu-mobile.webp"
      )}
    >
      <SEO
        item={{
          title: t("seo-menu-title"),
          description: t("seo-menu-description"),
          url: "https://www.drunkendragon.ca/menu",
        }}
      />
      {/* <MenuTab>
        <MenuHeader
          onClick={() => menuTabFn("menu")}
          className={menuTab === "menu" ? "active" : undefined}
        >
          {t("menu-tab-one")}
        </MenuHeader>
        <MenuHeader
          onClick={() => menuTabFn("drinks")}
          className={menuTab === "drinks" ? "active" : undefined}
        >
          {t("menu-tab-two")}
        </MenuHeader>
      </MenuTab> */}
      <div>
        <h1>Our menu : {t("menu-h-o")}</h1>
        <p>{t("menu-p-o")}</p>
        <p>{t("menu-p-t")}</p>
      </div>
      {/* <h2>Menu</h2> */}
      <ContentDiv bgImage={getResponsiveImageUrl("menu/MENU_PATTERN.webp")}>
        <nav>
          <MenuHeader
            onClick={() => menuSelectFn("menu")}
            className={menuButtons === "menu" ? "active" : undefined}
          >
            {t("menu-tab-one")}
          </MenuHeader>
          <MenuHeader
            onClick={() => menuSelectFn("drinks")}
            className={menuButtons === "drinks" ? "active" : undefined}
            title="Drinks menu"
          >
            {t("menu-tab-two")}
          </MenuHeader>
        </nav>
        <MobileNavTop>
          <MobileNavTop>
            <MenuHeader
              onClick={() => menuSelectFn("menu")}
              className={menuButtons === "menu" ? "active" : undefined}
              title={t("menu-tab-one-m")}
            >
              {t("menu-tab-one-m")}
            </MenuHeader>
            <MenuHeader
              onClick={() => menuSelectFn("drinks")}
              className={menuButtons === "drinks" ? "active" : undefined}
              title={t("menu-tab-one-m")}
            >
              {t("menu-tab-two-m")}
            </MenuHeader>
          </MobileNavTop>
        </MobileNavTop>
        {menuButtons === "menu" ? (
          <DropDownMenu
            ref={menuContainer}
            onClick={() =>
              isActive === false ? setIsActive(true) : setIsActive(false)
            }
          >
            {isActive === false ? (
              <ol>
                <li>
                  {selectedCategory?.name !== ""
                    ? i18n.language === "en"
                      ? selectedCategory?.name
                      : selectedCategory?.name_fr
                    : i18n.language === "en"
                    ? "Select a category"
                    : "Sélectionnez une catégorie"}
                </li>
              </ol>
            ) : null}
            {isActive === true ? (
              <ul ref={dropdownSection}>
                {foodCategoryKeyValuePairs?.map((el, ind) => (
                  <li
                    onClick={() => {
                      handleSelectedCategory(ind);
                    }}
                    key={Math.random() * 14000 + ind}
                  >
                    <a href={`#${el.link}`}>
                      {i18n.language === "en" ? el.name : el.name_fr}
                    </a>
                  </li>
                ))}
              </ul>
            ) : null}
            <ResponsiveImage
              src={"icons/chevron-down.svg"}
              alt="chevron-down"
            />
          </DropDownMenu>
        ) : null}
        {menuButtons === "drinks" ? (
          <DropDownMenu
            ref={menuContainer}
            onClick={() =>
              isActive === false ? setIsActive(true) : setIsActive(false)
            }
          >
            {isActive === false ? (
              <ol>
                <li>
                  {selectedCategoryDrinks?.name !== ""
                    ? i18n.language === "en"
                      ? selectedCategoryDrinks?.name
                      : selectedCategoryDrinks?.name_fr
                    : i18n.language === "en"
                    ? "Select a category"
                    : "Sélectionnez une catégorie"}
                </li>
              </ol>
            ) : null}
            {isActive === true ? (
              <ul ref={dropdownSection}>
                {drinksCategoryKeyValuePairs?.map((el, ind) => (
                  <li
                    onClick={() => {
                      handleSelectedCategoryDrinks(ind);
                    }}
                    key={Math.random() * 14000 + ind}
                  >
                    <a href={`#${el.link}`}>
                      {i18n.language === "en" ? el.name : el.name_fr}
                    </a>
                  </li>
                ))}
              </ul>
            ) : null}
            <ResponsiveImage
              src={"icons/chevron-down.svg"}
              alt="chevron-down"
            />
          </DropDownMenu>
        ) : null}
        {menuButtons === "menu" ? (
          <Food
            windowWidth={windowWidth}
            handleSelectedCategory={handleSelectedCategory}
          />
        ) : null}
        {menuButtons === "drinks" ? (
          <Drinks
            windowWidth={windowWidth}
            handleSelectedCategoryDrinks={handleSelectedCategoryDrinks}
          />
        ) : null}
      </ContentDiv>
    </MainDiv>
  );
};
const MainDiv = styled.div`
  width: 100vw;
  height: 100vh;
  transition: 0.4s ease-in-out;
  background-image: url(${({ bgImage }) => bgImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 120px 0 120px 150px;
  overflow: hidden;
  /* & > h2 {
    color: var(--gold_logo);
    font-weight: 300;
    text-transform: uppercase;
    font-size: 36px;
    line-height: 40px;
    @media screen and (min-width: 991px) {
      display: none;
    }
  } */
  & > div:nth-child(1) {
    margin: 120px 0 20px 0;
    width: 80%;
    height: auto;
    text-align: center;
    color: var(--silver);
    & > h1 {
      color: var(--gold_logo);
      font-weight: 300;
      text-transform: uppercase;
      font-size: 36px;
      line-height: 40px;
    }
    & > p {
      margin: 0;
    }
    @media screen and (min-width: 991px) {
      display: none;
    }
  }
  .react-horizontal-scrolling-menu--scroll-container {
    /* overflow-x: hidden; */
    padding-bottom: 10px;
  }
  @media screen and (max-width: 1050px) {
    padding: 80px 20px;
  }
  @media screen and (max-width: 991px) {
    /* start of medium tablet styles */
    /* display: block; */
    position: relative;
    padding: 0 20px;
    & > h2 {
      font-weight: 300;
      text-transform: uppercase;
      font-size: 28px;
      line-height: 50px;
      left: 20px;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    & > div:nth-child(1) {
      width: 100%;
      margin: 0;
      display: none;
      & > h1 {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 16px;
      }
      & > p {
        line-height: 28px;
        margin-bottom: 16px;
        color: var(--white);
      }
    }
  }
`;
const MenuTab = styled.div`
  color: var(--silver);
  position: absolute;
  top: 40px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0 auto;
  justify-content: space-evenly;
  width: 100%;
  max-width: 650px;
  z-index: 6;
  background-image: linear-gradient(
    to right,
    rgba(0.5, 0, 0, 0.5),
    rgba(0.5, 0, 0, 0.5)
  );
  border-radius: 10px;
  border: 2px solid #353838;
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    position: relative;
    top: unset;
    margin-bottom: 0;
  }
`;
const ContentDiv = styled.div`
  position: relative;
  width: 80%;
  /* padding-top: 50px; */
  background-image: linear-gradient(
      to right,
      rgba(0.5, 0, 0, 0.8),
      rgba(0.5, 0, 0, 0.8)
    ),
    url(${({ bgImage }) => bgImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  border-radius: 20px;
  padding: 40px 100px;
  color: var(--silver);
  height: 100%;
  max-height: 100%;
  /* max-height: 780px; */
  /* overflow: hidden; */
  /* padding-bottom: 120px; */
  & > nav:first-child {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-evenly;
    width: calc(100% - 50px);
    z-index: 6;
    background-image: linear-gradient(
      to right,
      rgba(0.5, 0, 0, 0.5),
      rgba(0.5, 0, 0, 0.5)
    );
    border-radius: 10px;
    border: 2px solid #353838;
  }
  @media screen and (min-width: 2400px) {
    /* start of desktop styles */
    /* max-height: 1000px; */
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    width: calc(100% - 40px);
    max-width: 650px;
    height: calc(100% - 120px);
    padding: 20px 15px;
    margin: 80px auto 0;
    position: fixed;
    top: 0;
    left: 20px;
    right: 20px;
    left: 50%;
    transform: translateX(-50%);
    & > nav:first-child {
      display: none;
    }
    & > nav:nth-child(2),
    & > nav:last-child {
      /* border: 2px solid #353838; */
      margin-bottom: 20px;
    }
    & > ul {
      bottom: 26px;
      max-height: 270px;
      overflow-y: scroll;
    }
  }
`;
const DropDownMenu = styled.div`
  position: relative;
  width: calc(100% - 50px);
  background-image: linear-gradient(
    to right,
    rgba(0.5, 0, 0, 0.5),
    rgba(0.5, 0, 0, 0.5)
  );
  border-radius: 10px;
  border: 2px solid #353838;
  height: 54px;
  display: flex;
  cursor: pointer;
  transition: 0.4s all;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  & > img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  & > ol {
    margin: 0;
    padding-left: 20px;
    & > li {
      text-transform: uppercase;
    }
  }
  & > ul {
    height: auto;
    max-height: 500px;
    overflow-y: scroll;
    position: absolute;
    width: 100%;
    border-radius: 10px;
    top: 0;
    left: 0%;
    outline: none;
    border: none;
    list-style: none;
    padding: 0;
    padding: 4px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 11;
    background: rgba(0.5, 0, 0, 0.9);
    ::-webkit-scrollbar {
      width: 8px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      border-radius: 8px;
      background-color: var(--scroll_track);
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: var(--scroll_thumb);
      border-radius: 10px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: var(--scroll_thumb_hover);
    }
    & > li {
      text-transform: uppercase;
      width: 100%;
      z-index: 11;
      cursor: pointer;
      padding: 0;
      transition: 0.1s all;
      padding: 10px;
      & > a {
        padding: 10px;
        width: 100%;
      }
      &:hover {
        background: var(--red);
        color: var(--white);
      }
    }
  }
  @media screen and (max-width: 991px) {
    width: calc(100% - 15px);
    border: 1px solid #353838;
    min-height: 35.2px;
    height: auto;
    & > ul {
      ::-webkit-scrollbar {
        width: 2px;
      }
      & > li {
        padding: 8px;
      }
    }
  }
`;
const MenuHeader = styled.h5`
  cursor: pointer;
  margin: 0;
  width: 100%;
  padding: 15px 0;
  border-radius: 10px;
  text-align: center;
  font-weight: 300;
  text-transform: uppercase;
  &.active {
    color: #101010;
    background-color: var(--gold_logo);
    transition: background-color 0.6s;
    font-weight: 300;
    &:hover {
      background-color: var(--gold_logo);
      color: #101010;
    }
  }
  &:hover {
    color: var(--silver);
    background-color: rgba(181, 151, 113, 0.4);
    transition: background-color 0.2s;
    font-weight: 300;
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    font-size: 16px;
    font-weight: 600;
    padding: 8px;
    &.active {
      font-weight: 600;
    }
  }
`;
const MobileNavTop = styled.nav`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  z-index: 6;
  background-image: linear-gradient(
    to right,
    rgba(0.5, 0, 0, 0.5),
    rgba(0.5, 0, 0, 0.5)
  );
  border-radius: 10px;
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
    display: none;
  }
`;
// const MobileNavBottom = styled.nav`
//   position: absolute;
//   bottom: 20px;
//   right: 20px;
//   left: 20px;
//   display: flex;
//   flex-flow: row nowrap;
//   align-items: center;
//   justify-content: space-evenly;
//   width: calc(100% - 40px);
//   z-index: 6;
//   background-image: linear-gradient(
//     to right,
//     rgba(0.5, 0, 0, 0.5),
//     rgba(0.5, 0, 0, 0.5)
//   );
//   border-radius: 10px;
//   border: 2px solid #353838;
//   @media screen and (min-width: 992px) {
//     /* start of desktop styles */
//     display: none;
//   }
// `;
export default Menu;
